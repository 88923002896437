import React from "react"
import { Flex, Styled } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Link from "@common/Link"

export const bgImage = graphql`
  fragment bgImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export default ({header}) => {
  const data = useStaticQuery(graphql`
    query {
      event1: file(
        relativePath: { eq: "events/austin-distel-rxpThOwuVgE-unsplash.png" }
      ) {
        ...bgImage
      }
      event2: file(
        relativePath: { eq: "events/neonbrand-1-aA2Fadydc-unsplash.png" }
      ) {
        ...bgImage
      }
    }
  `)

  return (
    <Flex variant="layout.subnav">
      {header ? <Styled.h1>{header}</Styled.h1> : null}
      <BackgroundImage fluid={data.event2.childImageSharp.fluid}>
        <Flex variant="layout.events.item">
          <Styled.h4>BIG DATA Conference, Berlin</Styled.h4>
          <Styled.p>09.06.2020, Pankof, Berlin</Styled.p>
          <Link to="/conferences" variant="subnav">
            Zobacz
          </Link>
        </Flex>
      </BackgroundImage>
    </Flex>
  )
}
