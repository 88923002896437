export const events = [
    {
      kind: "meetup",
      id: 1,
      status: "string",
      htmlLink: "string",
      created: "datetime",
      updated: "datetime",
      summary: "Codete Meetup",
      description: "string",
      location: "string",
      colorId: "string",
      creator: {
        id: "string",
        email: "string",
        displayName: "string",
        self: "boolean",
      },
      organizer: {
        id: "string",
        email: "string",
        displayName: "Codete",
        self: "boolean",
      },
      start: {
        date: "2020-06-05",
        dateTime: "2020-06-05T18:30",
        timeZone: "string",
      },
      end: {
        date: "date",
        dateTime: "datetime",
        timeZone: "string",
      },
      endTimeUnspecified: "boolean",
      recurrence: ["string"],
      recurringEventId: "string",
      originalStartTime: {
        date: "date",
        dateTime: "datetime",
        timeZone: "string",
      },
      transparency: "string",
      visibility: "string",
      iCalUID: "string",
      sequence: "integer",
      attendees: [
        {
          id: "string",
          email: "string",
          displayName: "string",
          organizer: "boolean",
          self: "boolean",
          resource: "boolean",
          optional: "boolean",
          responseStatus: "string",
          comment: "string",
          additionalGuests: "integer",
        },
      ],
      attendeesOmitted: "boolean",
      extendedProperties: {
        private: {},
        shared: {},
      },
      hangoutLink: "string",
      conferenceData: {
        createRequest: {
          requestId: "string",
          conferenceSolutionKey: {
            type: "string",
          },
          status: {
            statusCode: "string",
          },
        },
        entryPoints: [
          {
            entryPointType: "string",
            uri: "string",
            label: "string",
            pin: "string",
            accessCode: "string",
            meetingCode: "string",
            passcode: "string",
            password: "string",
          },
        ],
        conferenceSolution: {
          key: {
            type: "string",
          },
          name: "string",
          iconUri: "string",
        },
        conferenceId: "string",
        signature: "string",
        notes: "string",
        gadget: {
          type: "string",
          title: "string",
          link: "string",
          iconLink: "string",
          width: "integer",
          height: "integer",
          display: "string",
          preferences: {},
        },
        anyoneCanAddSelf: "boolean",
        guestsCanInviteOthers: "boolean",
        guestsCanModify: "boolean",
        guestsCanSeeOtherGuests: "boolean",
        privateCopy: "boolean",
        locked: "boolean",
        reminders: {
          useDefault: "boolean",
          overrides: [
            {
              method: "string",
              minutes: "integer",
            },
          ],
        },
        source: {
          url: "string",
          title: "string",
        },
        attachments: [
          {
            fileUrl: "string",
            title: "string",
            mimeType: "string",
            iconLink: "string",
            fileId: "string",
          },
        ],
      },
    },
    {
        kind: "meetup",
        id: 2,
        status: "string",
        htmlLink: "string",
        created: "datetime",
        updated: "datetime",
        summary: "Codete Meetup",
        description: "string",
        location: "string",
        colorId: "string",
        creator: {
          id: "string",
          email: "string",
          displayName: "string",
          self: "boolean",
        },
        organizer: {
          id: "string",
          email: "string",
          displayName: "Codete",
          self: "boolean",
        },
        start: {
          date: "2020-07-17",
          dateTime: "2020-07-17T18:30",
          timeZone: "string",
        },
        end: {
          date: "date",
          dateTime: "datetime",
          timeZone: "string",
        },
        endTimeUnspecified: "boolean",
        recurrence: ["string"],
        recurringEventId: "string",
        originalStartTime: {
          date: "date",
          dateTime: "datetime",
          timeZone: "string",
        },
        transparency: "string",
        visibility: "string",
        iCalUID: "string",
        sequence: "integer",
        attendees: [
          {
            id: "string",
            email: "string",
            displayName: "string",
            organizer: "boolean",
            self: "boolean",
            resource: "boolean",
            optional: "boolean",
            responseStatus: "string",
            comment: "string",
            additionalGuests: "integer",
          },
        ],
        attendeesOmitted: "boolean",
        extendedProperties: {
          private: {},
          shared: {},
        },
        hangoutLink: "string",
        conferenceData: {
          createRequest: {
            requestId: "string",
            conferenceSolutionKey: {
              type: "string",
            },
            status: {
              statusCode: "string",
            },
          },
          entryPoints: [
            {
              entryPointType: "string",
              uri: "string",
              label: "string",
              pin: "string",
              accessCode: "string",
              meetingCode: "string",
              passcode: "string",
              password: "string",
            },
          ],
          conferenceSolution: {
            key: {
              type: "string",
            },
            name: "string",
            iconUri: "string",
          },
          conferenceId: "string",
          signature: "string",
          notes: "string",
          gadget: {
            type: "string",
            title: "string",
            link: "string",
            iconLink: "string",
            width: "integer",
            height: "integer",
            display: "string",
            preferences: {},
          },
          anyoneCanAddSelf: "boolean",
          guestsCanInviteOthers: "boolean",
          guestsCanModify: "boolean",
          guestsCanSeeOtherGuests: "boolean",
          privateCopy: "boolean",
          locked: "boolean",
          reminders: {
            useDefault: "boolean",
            overrides: [
              {
                method: "string",
                minutes: "integer",
              },
            ],
          },
          source: {
            url: "string",
            title: "string",
          },
          attachments: [
            {
              fileUrl: "string",
              title: "string",
              mimeType: "string",
              iconLink: "string",
              fileId: "string",
            },
          ],
        },
      },
      {
        kind: "conference",
        id: 3,
        status: "string",
        htmlLink: "string",
        created: "datetime",
        updated: "datetime",
        summary: "Codete Java Conference",
        description: "string",
        location: "string",
        colorId: "string",
        creator: {
          id: "string",
          email: "string",
          displayName: "string",
          self: "boolean",
        },
        organizer: {
          id: "string",
          email: "string",
          displayName: "Codete",
          self: "boolean",
        },
        start: {
          date: "2020-06-15",
          dateTime: "2020-06-15T18:30",
          timeZone: "string",
        },
        end: {
          date: "date",
          dateTime: "datetime",
          timeZone: "string",
        },
        endTimeUnspecified: "boolean",
        recurrence: ["string"],
        recurringEventId: "string",
        originalStartTime: {
          date: "date",
          dateTime: "datetime",
          timeZone: "string",
        },
        transparency: "string",
        visibility: "string",
        iCalUID: "string",
        sequence: "integer",
        attendees: [
          {
            id: "string",
            email: "string",
            displayName: "string",
            organizer: "boolean",
            self: "boolean",
            resource: "boolean",
            optional: "boolean",
            responseStatus: "string",
            comment: "string",
            additionalGuests: "integer",
          },
        ],
        attendeesOmitted: "boolean",
        extendedProperties: {
          private: {},
          shared: {},
        },
        hangoutLink: "string",
        conferenceData: {
          createRequest: {
            requestId: "string",
            conferenceSolutionKey: {
              type: "string",
            },
            status: {
              statusCode: "string",
            },
          },
          entryPoints: [
            {
              entryPointType: "string",
              uri: "string",
              label: "string",
              pin: "string",
              accessCode: "string",
              meetingCode: "string",
              passcode: "string",
              password: "string",
            },
          ],
          conferenceSolution: {
            key: {
              type: "string",
            },
            name: "string",
            iconUri: "string",
          },
          conferenceId: "string",
          signature: "string",
          notes: "string",
          gadget: {
            type: "string",
            title: "string",
            link: "string",
            iconLink: "string",
            width: "integer",
            height: "integer",
            display: "string",
            preferences: {},
          },
          anyoneCanAddSelf: "boolean",
          guestsCanInviteOthers: "boolean",
          guestsCanModify: "boolean",
          guestsCanSeeOtherGuests: "boolean",
          privateCopy: "boolean",
          locked: "boolean",
          reminders: {
            useDefault: "boolean",
            overrides: [
              {
                method: "string",
                minutes: "integer",
              },
            ],
          },
          source: {
            url: "string",
            title: "string",
          },
          attachments: [
            {
              fileUrl: "string",
              title: "string",
              mimeType: "string",
              iconLink: "string",
              fileId: "string",
            },
          ],
        },
      },
      {
        kind: "workshop",
        id: 4,
        status: "string",
        htmlLink: "string",
        created: "datetime",
        updated: "datetime",
        summary: "Codete Workshops w Krakowie",
        description: "string",
        location: "string",
        colorId: "string",
        creator: {
          id: "string",
          email: "string",
          displayName: "string",
          self: "boolean",
        },
        organizer: {
          id: "string",
          email: "string",
          displayName: "Codete",
          self: "boolean",
        },
        start: {
          date: "2020-04-25",
          dateTime: "2020-04-25T18:30",
          timeZone: "string",
        },
        end: {
          date: "date",
          dateTime: "datetime",
          timeZone: "string",
        },
        endTimeUnspecified: "boolean",
        recurrence: ["string"],
        recurringEventId: "string",
        originalStartTime: {
          date: "date",
          dateTime: "datetime",
          timeZone: "string",
        },
        transparency: "string",
        visibility: "string",
        iCalUID: "string",
        sequence: "integer",
        attendees: [
          {
            id: "string",
            email: "string",
            displayName: "string",
            organizer: "boolean",
            self: "boolean",
            resource: "boolean",
            optional: "boolean",
            responseStatus: "string",
            comment: "string",
            additionalGuests: "integer",
          },
        ],
        attendeesOmitted: "boolean",
        extendedProperties: {
          private: {},
          shared: {},
        },
        hangoutLink: "string",
        conferenceData: {
          createRequest: {
            requestId: "string",
            conferenceSolutionKey: {
              type: "string",
            },
            status: {
              statusCode: "string",
            },
          },
          entryPoints: [
            {
              entryPointType: "string",
              uri: "string",
              label: "string",
              pin: "string",
              accessCode: "string",
              meetingCode: "string",
              passcode: "string",
              password: "string",
            },
          ],
          conferenceSolution: {
            key: {
              type: "string",
            },
            name: "string",
            iconUri: "string",
          },
          conferenceId: "string",
          signature: "string",
          notes: "string",
          gadget: {
            type: "string",
            title: "string",
            link: "string",
            iconLink: "string",
            width: "integer",
            height: "integer",
            display: "string",
            preferences: {},
          },
          anyoneCanAddSelf: "boolean",
          guestsCanInviteOthers: "boolean",
          guestsCanModify: "boolean",
          guestsCanSeeOtherGuests: "boolean",
          privateCopy: "boolean",
          locked: "boolean",
          reminders: {
            useDefault: "boolean",
            overrides: [
              {
                method: "string",
                minutes: "integer",
              },
            ],
          },
          source: {
            url: "string",
            title: "string",
          },
          attachments: [
            {
              fileUrl: "string",
              title: "string",
              mimeType: "string",
              iconLink: "string",
              fileId: "string",
            },
          ],
        },
      },
      {
        kind: "convent",
        id: 5,
        status: "string",
        htmlLink: "string",
        created: "datetime",
        updated: "datetime",
        summary: "Codete Convent Berlin",
        description: "string",
        location: "string",
        colorId: "string",
        creator: {
          id: "string",
          email: "string",
          displayName: "string",
          self: "boolean",
        },
        organizer: {
          id: "string",
          email: "string",
          displayName: "Codete",
          self: "boolean",
        },
        start: {
          date: "2020-07-05",
          dateTime: "2020-07-05T18:30",
          timeZone: "string",
        },
        end: {
          date: "date",
          dateTime: "datetime",
          timeZone: "string",
        },
        endTimeUnspecified: "boolean",
        recurrence: ["string"],
        recurringEventId: "string",
        originalStartTime: {
          date: "date",
          dateTime: "datetime",
          timeZone: "string",
        },
        transparency: "string",
        visibility: "string",
        iCalUID: "string",
        sequence: "integer",
        attendees: [
          {
            id: "string",
            email: "string",
            displayName: "string",
            organizer: "boolean",
            self: "boolean",
            resource: "boolean",
            optional: "boolean",
            responseStatus: "string",
            comment: "string",
            additionalGuests: "integer",
          },
        ],
        attendeesOmitted: "boolean",
        extendedProperties: {
          private: {},
          shared: {},
        },
        hangoutLink: "string",
        conferenceData: {
          createRequest: {
            requestId: "string",
            conferenceSolutionKey: {
              type: "string",
            },
            status: {
              statusCode: "string",
            },
          },
          entryPoints: [
            {
              entryPointType: "string",
              uri: "string",
              label: "string",
              pin: "string",
              accessCode: "string",
              meetingCode: "string",
              passcode: "string",
              password: "string",
            },
          ],
          conferenceSolution: {
            key: {
              type: "string",
            },
            name: "string",
            iconUri: "string",
          },
          conferenceId: "string",
          signature: "string",
          notes: "string",
          gadget: {
            type: "string",
            title: "string",
            link: "string",
            iconLink: "string",
            width: "integer",
            height: "integer",
            display: "string",
            preferences: {},
          },
          anyoneCanAddSelf: "boolean",
          guestsCanInviteOthers: "boolean",
          guestsCanModify: "boolean",
          guestsCanSeeOtherGuests: "boolean",
          privateCopy: "boolean",
          locked: "boolean",
          reminders: {
            useDefault: "boolean",
            overrides: [
              {
                method: "string",
                minutes: "integer",
              },
            ],
          },
          source: {
            url: "string",
            title: "string",
          },
          attachments: [
            {
              fileUrl: "string",
              title: "string",
              mimeType: "string",
              iconLink: "string",
              fileId: "string",
            },
          ],
        },
      },
      {
        kind: "meetup",
        id: 6,
        status: "string",
        htmlLink: "string",
        created: "datetime",
        updated: "datetime",
        summary: "Codete Meetup 6",
        description: "string",
        location: "string",
        colorId: "string",
        creator: {
          id: "string",
          email: "string",
          displayName: "string",
          self: "boolean",
        },
        organizer: {
          id: "string",
          email: "string",
          displayName: "Codete",
          self: "boolean",
        },
        start: {
          date: "2020-06-05",
          dateTime: "2020-06-05T18:30",
          timeZone: "string",
        },
        end: {
          date: "date",
          dateTime: "datetime",
          timeZone: "string",
        },
        endTimeUnspecified: "boolean",
        recurrence: ["string"],
        recurringEventId: "string",
        originalStartTime: {
          date: "date",
          dateTime: "datetime",
          timeZone: "string",
        },
        transparency: "string",
        visibility: "string",
        iCalUID: "string",
        sequence: "integer",
        attendees: [
          {
            id: "string",
            email: "string",
            displayName: "string",
            organizer: "boolean",
            self: "boolean",
            resource: "boolean",
            optional: "boolean",
            responseStatus: "string",
            comment: "string",
            additionalGuests: "integer",
          },
        ],
        attendeesOmitted: "boolean",
        extendedProperties: {
          private: {},
          shared: {},
        },
        hangoutLink: "string",
        conferenceData: {
          createRequest: {
            requestId: "string",
            conferenceSolutionKey: {
              type: "string",
            },
            status: {
              statusCode: "string",
            },
          },
          entryPoints: [
            {
              entryPointType: "string",
              uri: "string",
              label: "string",
              pin: "string",
              accessCode: "string",
              meetingCode: "string",
              passcode: "string",
              password: "string",
            },
          ],
          conferenceSolution: {
            key: {
              type: "string",
            },
            name: "string",
            iconUri: "string",
          },
          conferenceId: "string",
          signature: "string",
          notes: "string",
          gadget: {
            type: "string",
            title: "string",
            link: "string",
            iconLink: "string",
            width: "integer",
            height: "integer",
            display: "string",
            preferences: {},
          },
          anyoneCanAddSelf: "boolean",
          guestsCanInviteOthers: "boolean",
          guestsCanModify: "boolean",
          guestsCanSeeOtherGuests: "boolean",
          privateCopy: "boolean",
          locked: "boolean",
          reminders: {
            useDefault: "boolean",
            overrides: [
              {
                method: "string",
                minutes: "integer",
              },
            ],
          },
          source: {
            url: "string",
            title: "string",
          },
          attachments: [
            {
              fileUrl: "string",
              title: "string",
              mimeType: "string",
              iconLink: "string",
              fileId: "string",
            },
          ],
        },
      },
      {
        kind: "meetup",
        id: 7,
        status: "string",
        htmlLink: "string",
        created: "datetime",
        updated: "datetime",
        summary: "Codete Meetup 7",
        description: "string",
        location: "string",
        colorId: "string",
        creator: {
          id: "string",
          email: "string",
          displayName: "string",
          self: "boolean",
        },
        organizer: {
          id: "string",
          email: "string",
          displayName: "Codete",
          self: "boolean",
        },
        start: {
          date: "2020-06-05",
          dateTime: "2020-06-05T18:30",
          timeZone: "string",
        },
        end: {
          date: "date",
          dateTime: "datetime",
          timeZone: "string",
        },
        endTimeUnspecified: "boolean",
        recurrence: ["string"],
        recurringEventId: "string",
        originalStartTime: {
          date: "date",
          dateTime: "datetime",
          timeZone: "string",
        },
        transparency: "string",
        visibility: "string",
        iCalUID: "string",
        sequence: "integer",
        attendees: [
          {
            id: "string",
            email: "string",
            displayName: "string",
            organizer: "boolean",
            self: "boolean",
            resource: "boolean",
            optional: "boolean",
            responseStatus: "string",
            comment: "string",
            additionalGuests: "integer",
          },
        ],
        attendeesOmitted: "boolean",
        extendedProperties: {
          private: {},
          shared: {},
        },
        hangoutLink: "string",
        conferenceData: {
          createRequest: {
            requestId: "string",
            conferenceSolutionKey: {
              type: "string",
            },
            status: {
              statusCode: "string",
            },
          },
          entryPoints: [
            {
              entryPointType: "string",
              uri: "string",
              label: "string",
              pin: "string",
              accessCode: "string",
              meetingCode: "string",
              passcode: "string",
              password: "string",
            },
          ],
          conferenceSolution: {
            key: {
              type: "string",
            },
            name: "string",
            iconUri: "string",
          },
          conferenceId: "string",
          signature: "string",
          notes: "string",
          gadget: {
            type: "string",
            title: "string",
            link: "string",
            iconLink: "string",
            width: "integer",
            height: "integer",
            display: "string",
            preferences: {},
          },
          anyoneCanAddSelf: "boolean",
          guestsCanInviteOthers: "boolean",
          guestsCanModify: "boolean",
          guestsCanSeeOtherGuests: "boolean",
          privateCopy: "boolean",
          locked: "boolean",
          reminders: {
            useDefault: "boolean",
            overrides: [
              {
                method: "string",
                minutes: "integer",
              },
            ],
          },
          source: {
            url: "string",
            title: "string",
          },
          attachments: [
            {
              fileUrl: "string",
              title: "string",
              mimeType: "string",
              iconLink: "string",
              fileId: "string",
            },
          ],
        },
      },
      {
        kind: "workshop",
        id: 8,
        status: "string",
        htmlLink: "string",
        created: "datetime",
        updated: "datetime",
        summary: "Codete Workshops 8",
        description: "string",
        location: "string",
        colorId: "string",
        creator: {
          id: "string",
          email: "string",
          displayName: "string",
          self: "boolean",
        },
        organizer: {
          id: "string",
          email: "string",
          displayName: "Codete",
          self: "boolean",
        },
        start: {
          date: "2020-07-17",
          dateTime: "2020-07-17T20:00",
          timeZone: "string",
        },
        end: {
          date: "date",
          dateTime: "datetime",
          timeZone: "string",
        },
        endTimeUnspecified: "boolean",
        recurrence: ["string"],
        recurringEventId: "string",
        originalStartTime: {
          date: "date",
          dateTime: "datetime",
          timeZone: "string",
        },
        transparency: "string",
        visibility: "string",
        iCalUID: "string",
        sequence: "integer",
        attendees: [
          {
            id: "string",
            email: "string",
            displayName: "string",
            organizer: "boolean",
            self: "boolean",
            resource: "boolean",
            optional: "boolean",
            responseStatus: "string",
            comment: "string",
            additionalGuests: "integer",
          },
        ],
        attendeesOmitted: "boolean",
        extendedProperties: {
          private: {},
          shared: {},
        },
        hangoutLink: "string",
        conferenceData: {
          createRequest: {
            requestId: "string",
            conferenceSolutionKey: {
              type: "string",
            },
            status: {
              statusCode: "string",
            },
          },
          entryPoints: [
            {
              entryPointType: "string",
              uri: "string",
              label: "string",
              pin: "string",
              accessCode: "string",
              meetingCode: "string",
              passcode: "string",
              password: "string",
            },
          ],
          conferenceSolution: {
            key: {
              type: "string",
            },
            name: "string",
            iconUri: "string",
          },
          conferenceId: "string",
          signature: "string",
          notes: "string",
          gadget: {
            type: "string",
            title: "string",
            link: "string",
            iconLink: "string",
            width: "integer",
            height: "integer",
            display: "string",
            preferences: {},
          },
          anyoneCanAddSelf: "boolean",
          guestsCanInviteOthers: "boolean",
          guestsCanModify: "boolean",
          guestsCanSeeOtherGuests: "boolean",
          privateCopy: "boolean",
          locked: "boolean",
          reminders: {
            useDefault: "boolean",
            overrides: [
              {
                method: "string",
                minutes: "integer",
              },
            ],
          },
          source: {
            url: "string",
            title: "string",
          },
          attachments: [
            {
              fileUrl: "string",
              title: "string",
              mimeType: "string",
              iconLink: "string",
              fileId: "string",
            },
          ],
        },
      },
  ]