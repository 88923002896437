import React, { useState } from "react"
import getISODay from "date-fns/getISODay"
import addDays from "date-fns/addDays"
import addMonths from "date-fns/addMonths"
import getYear from "date-fns/getYear"
import startOfMonth from "date-fns/startOfMonth"
import { pl } from "date-fns/locale"
import format from "date-fns/format"
import isEqual from "date-fns/isEqual"
import { Badge, Box, Button, Flex, Styled } from "theme-ui"
import Prev from "@buttons/Prev"
import Next from "@buttons/Next"
import { days, weekdays } from "@tools"

const today = startOfMonth(new Date())

export default ({ events }) => {
  const [date, setDate] = useState(today)
  const [selectedDate, selectDate] = useState(date)
  const currentDayEvents = events.filter(event => event.start.date === format(selectedDate, "yyyy-MM-dd"))

  return (
    <Flex variant="layout.calendar.content">
      <Flex variant="layout.calendar.eventData">
        {currentDayEvents.map(event => (
          <Flex as="section" sx={{
            width: '100%'
          }}>
            <Styled.h4>{event.summary}</Styled.h4>
          </Flex>
        ))}
      </Flex>
      <Flex variant="layout.calendar.widget">
        <Flex variant="layout.calendar.heading">
          <Styled.h5>
            {format(date, "LLLL", { locale: pl })}
            {`  `}
            {getYear(date)}
          </Styled.h5>
          <Prev onClick={() => setDate(addMonths(date, -1))} />
          <Next onClick={() => setDate(addMonths(date, 1))} />
        </Flex>
        <Box variant="layout.calendar.weekdays">
          {weekdays.map(weekday => (
            <Flex
              key={weekday}
              variant="layout.calendar.weekday"
              a11yTitle={weekday}
            >
              {weekday}
            </Flex>
          ))}
        </Box>
        <Box
          variant="layout.calendar.days"
          sx={{
            "> div:first-child": {
              gridColumn: getISODay(date),
            },
          }}
        >
          {days(date).map((x, i) => {
            const currentDate = addDays(date, i)
            const currentDay = format(currentDate, "dd")

            const currentDayEventsCount = events.filter(
              event => event.start.date === format(currentDate, "yyyy-MM-dd")
            ).length

            return (
              <Flex key={format(i, "dd-mm-yyyy")} variant="layout.calendar.day">
                {!!currentDayEventsCount ? (
                  <Button
                    key={format(i, "dd-mm-yyyy")}
                    variant="event"
                    onClick={() => selectDate(currentDate)}
                    sx={{
                      backgroundColor:
                        isEqual(currentDate, selectedDate) && `primary`,
                    }}
                  >
                    <span>{currentDay}</span>
                    <Badge variant="circle">{currentDayEventsCount}</Badge>
                  </Button>
                ) : (
                  <span>{currentDay}</span>
                )}
              </Flex>
            )
          })}
        </Box>
      </Flex>
    </Flex>
  )
}
