import { css } from "@emotion/core"

export default css`
  @import url("https://fonts.googleapis.com/css?family=Poppins:300,400,600,700|Work+Sans:400,600,700&display=swap");

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #DDDCDC;
  }

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
    outline: none;
  }

  button:active {
    outline: none;
  }

  button:focus {
    outline: none;
  }

  *:focus {
    outline: none !important;
  }

  .slick-dots {
    display: flex !important;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 32px;
    list-style-type: none;
    flex: 1;
    flex-direction: column;
    margin: 40px 0 40px 0;
    padding: 0;

    :before {
      content: '01';
      position: absolute;
      top: -40px;
      color: white;
      font-family: "Poppins";
      width: 100%;
      text-align: center;
    }
    :after {
      content: '03';
      position: absolute;
      bottom: -40px;
      color: white;
      font-family: "Poppins";
      width: 100%;
      text-align: center;
    }
  }
  .slick-dots > li {
    flex: 1;
    width: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .slick-dots > li:not(.slick-active) {
    cursor: pointer;
    opacity: 0.27;
  }

  .calendar {
    background-color: #DDDCDC;
  }

  figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 306px;
  }
  [data-reach-dialog-overlay] {
    z-index: 3;
  }
`
