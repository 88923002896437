import React, { useEffect } from "react"
import { Box, Container, Flex, Styled, useColorMode } from "theme-ui"
import Layout from "@layouts/calendar"
import SEO from "@components/seo"

import Calendar from "@components/calendar"
import EventCategories from "@components/EventCategories"
import TopEventsList from "@components/TopEventsList"

// mockup data
import { events } from "@queries"

export default () => {
  const [colorMode, setColorMode] = useColorMode()

  useEffect(() => setColorMode("dark"), [colorMode, setColorMode])

  return (
    <Layout>
      <SEO title="Home" />
      <Flex as="section" variant="layout.calendar.container">
        <Container variant="layout.flexColumn">
          <Flex variant="layout.calendar.header">
            <Flex>
              <Box>
                <Styled.h1>Kalendarz eventowy</Styled.h1>
                <Styled.p>
                  Bądź zawsze na bieżąco z wydarzeniami w twojej okolicy.
                </Styled.p>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Flex>

      <Container as="section">
        <Calendar events={events} />
        <TopEventsList header="Top Eventy:" />
        <EventCategories header="Główne kategorie:" />
      </Container>
    </Layout>
  )
}
