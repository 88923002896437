import React from "react"
import { IconButton } from "theme-ui"
import ArrowRightIcon from "@assets/arrowRight.svg"

export default ({colorMode = "light", ...props}) => (
  <IconButton
    aria-label="Next"
    variant={`buttons.next.${colorMode}`}
    {...props}
  >
    <ArrowRightIcon />
  </IconButton>
)
