import React from "react"
import { IconButton } from "theme-ui"
import ArrowLeftIcon from "@assets/arrowLeft.svg"

export default ({colorMode = "light", ...props}) => (
  <IconButton
    aria-label="Next"
    variant={`buttons.prev.${colorMode}`}
    {...props}
  >
    <ArrowLeftIcon />
  </IconButton>
)
