import React from "react"
import PropTypes from "prop-types"
import "normalize.css"

import { Container, Flex } from "theme-ui"
import { Global } from "@emotion/core"
import Logo from "@common/Logo"
import AvatarButton from "@components/AvatarButton"
import MainNavigation from "@components/MainNavigation"
import Newsletter from "@forms/Newsletter"
import Footer from "@components/Footer"
import calendarStyles from "@styles/calendar"
import { colorModes } from "@const"

const { light } = colorModes
const colorMode = light

const Layout = ({ children }) => {
  return (
    <>
      <Global styles={calendarStyles} />
      <Container as="header" variant="layout.header">
        <Logo />
        <AvatarButton colorMode={colorMode} />
      </Container>
      <MainNavigation colorMode={colorMode} />
      <Flex as="main" variant="layout.main">
        {children}
      </Flex>
      <Newsletter />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
